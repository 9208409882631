
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, PropSync} from 'vue-property-decorator';

import {authStore} from '@/store/__STORE_auth';

@Component
/**
 *
 */
export default class AfterCall extends Vue {
  name: 'After Call';

  @PropSync('webrtcAlias', {type: String, required: true}) readonly __PROPalias : string
  @PropSync('reloadRequired', {type: Boolean}) readonly __PROPreloadRequired : boolean

  isOwner = false;

  /**
   * Happens on mount
   * @return {void}
   */
  mounted(): void {
    if (this.__PROPreloadRequired) {
      window.location.reload();
    }

    this.isOwner = JSON.parse(localStorage.getItem('isOwner')) === true;
    this.__EMITsetRoomBrand();
    this.__EMITdisableFullscreen();
    this.removeFavicon();
  }

  /**
   * remove favicon
   * @return {void}
   */
  removeFavicon(): void {
    const link = document.createElement('link');
    const oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = '';
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }

  /**
   * @param {string} location
   * @return {void}
   */
  redirect(location: string): void {
    localStorage.removeItem('isOwner');
    switch (location) {
      case 'recordings':
        this.$router.push({name: 'recordings'});
        break;
      case 'dashboard':
        this.$router.push({name: 'dashboard'});
        localStorage.removeItem('guest_pin');
        break;
      case 'register':
        this.$router.push({name: 'register'});
        break;
      case 'host':
        this.$router.push({
          name: 'host',
          params: {
            webrtcAlias: this.__PROPalias,
          },
        });
        break;
    }
  }

  /**
   * @return {Boolean}
   */
  get isLoggedIn(): Boolean {
    return authStore.__GETTERisUserLoggedIn;
  }

  /**
   * @return {null}
   */
  @Emit('set-room-brand')
  __EMITsetRoomBrand(): null {
    return null;
  }

  /**
   * @return {void}
   */
  @Emit('disable-fullscreen')
  __EMITdisableFullscreen(): void {}
}
